export const saveSigil = ({name, shapes = [], criptoText, editIndex, originalText}) => {
  try {
    let sigilStorage = JSON.parse(localStorage.getItem('sigils'))
    sigilStorage = Array.isArray(sigilStorage) ? sigilStorage : [];
    const sigil = {
      name,
      shapes: shapes,
      originalText,
      criptoText,
      createAt: new Date()
    }
    if(editIndex !== undefined || editIndex != null){
      sigilStorage[editIndex] = sigil
    }else {
      sigilStorage.push(sigil)
    }
    localStorage.setItem('sigils', JSON.stringify(sigilStorage));
    return {...sigil, index: sigilStorage.length -1};
  }catch(error){
    return {
      error: 'No es posible guardar la informacón' 
    }
  }
}


export const formatDate = (date, locale, options) => {
  if(typeof date === 'string'){
    return new Intl.DateTimeFormat(locale, options).format(new Date(date))
  }
  return new Intl.DateTimeFormat(locale, options).format(date)
}; 