import { Box, Button, Modal, Typography } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useSigilContext } from "../../context/sigilContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  bgcolor: "#2C2C2C", // Cambia el color de fondo
  boxShadow: 24,
  p: 4,
  color: "white", // Cambia el color del texto
  borderRadius: "8px",
};

const DeleteModal = ({deleteSigil, index}) => {
  const { 
    openDeleteModal,
    setOpenDeleteModal,
    i18n
  } = useSigilContext();

  const translate = i18n.deleteModal;

  return (
    <Modal
      open={openDeleteModal}
      onClose={() => setOpenDeleteModal(false)}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(255, 255, 255, 0.3)",
          },
        },
      }}
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Typography variant="h6" component="h2" sx={{ color: "white" }}>
              {translate.title}
            </Typography>
            <CloseOutlinedIcon onClick={() => setOpenDeleteModal(false)} />
          </Box>
          <span>{translate.description}</span>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              variant="text"
              sx={{ marginLeft: "20px" }}
              onClick={() => setOpenDeleteModal(false)}
            >
              {translate.cancelButton}
            </Button>
            <Button
              variant='outlined'
              sx={{ marginLeft: "20px" }}
              onClick={() => deleteSigil(index)}
            >
              {translate.deleteButton}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
