import React from "react";
import { SigilProvider } from "./context/sigilContext";
import { ThemeProvider } from '@mui/material/styles';
import { themeSigil } from "./Theme";
import { CssBaseline } from "@mui/material";
import Main from "./pages/Main";
import { Analytics } from "@vercel/analytics/react"

function App() {

  return (
    <SigilProvider>
      <ThemeProvider theme={themeSigil}>
        <CssBaseline />
        <Main />
        <Analytics />
      </ThemeProvider>
    </SigilProvider>
  );
}

export default App;
