import { Box, Drawer, IconButton } from "@mui/material";
import { useSigilContext } from "../../context/sigilContext";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const DrawerImage = () => {

  const { 
    openImage, 
    setOpenImage,
    i18n,
  } = useSigilContext();

  const translate = i18n.info;

  return ( 
    <Drawer 
      anchor="right" 
      open={openImage} 
      onClose={() => setOpenImage(false)}
      PaperProps={{
        sx: {
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',    // Centrar horizontalmente
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '24px',
          padding: '15px'
        }}
      >
        <h2>{translate.imageName}</h2>  

        <img 
          src={require('../../assets/figure2.png')} 
          alt={translate.imageName}
          style={{
            maxWidth: "100%",  // Imagen no excede el ancho del drawer
            maxHeight: "100%", // Imagen no excede la altura del drawer
            objectFit: "contain", // Ajusta sin distorsionar la imagen
          }} 
        />
        <IconButton 
          sx={{
            backgroundColor: 'white', 
            width: '54px',
            height: '54px', 
            marginBottom: '50px' 
          }}
          onClick={ () => { setOpenImage(false) }}
        > 
            <CloseOutlinedIcon />
        </IconButton>
      </Box>
    </Drawer>
  );
}
 
export default DrawerImage;