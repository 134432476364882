import { Box, Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Canvas from '../components/canvas/Canvas';
import PaintTools from '../components/paintTools/PaintTools';
import ColorTool from '../components/colorTool/ColorTool';
import { useSigilContext } from '../context/sigilContext';
import { useNavigate } from 'react-router-dom';
import SaveModal from '../components/saveModal/SaveModal';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';


const DrawBox = () => {
  const { 
    criptoText, 
    setOpenModal, 
    savedName, 
    setMessage,
    i18n
  } = useSigilContext();
  const navigate = useNavigate();
  const stageRefs = useRef([]);
  const translate = { ...i18n.drawing, ...i18n.message};

  const handleDownload = async () => {
    const uri = stageRefs.current.toDataURL();
    const base64Data = uri.split(',')[1]; // Extrae el contenido base64
  
    if (Capacitor.isNativePlatform()) {
      try {
        const fileName = `${savedName || 'sigilo'}.png`;
        await Filesystem.writeFile({
          path: fileName,
          data: base64Data,
          directory: Directory.Documents, // Puedes usar Directory.Data o Directory.External para Android
        });
        setMessage({
          severity: 'success',
          text: translate.export.success,
        })
      } catch (error) {
        setMessage({
          severity: 'error',
          text: translate.export.error 
        })
      }
    } else {
      const link = document.createElement('a');
      link.href = uri;
      link.download = `${savedName || 'sigilo'}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  
  useEffect(() => {
    // setTimeout(() => {
      if(!criptoText){
        navigate('/')
      }
    // }, 3000);
  }, [criptoText])

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      marginX: '16px', 
      maxHeight: '100vh', 
      flexGrow: 1 
    }}>  {/* Ajuste de altura total */}
      <h1>{criptoText.replace(/\s+/g, '').toUpperCase().replace() }</h1>
      
      <Box 
        sx={{
          border: '1px solid white',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 8,     // Hace que el contenido ocupe el espacio restante
          minHeight: '300px',
          maxHeight: { sx: '300px',  md: '100%'}
        }}
      >
        {/* Canvas */}
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            flexGrow: 1,        // El Canvas ocupa el máximo espacio disponible
            minHeight: '300px', // Asegura que el Canvas tenga un alto mínimo
            maxHeight: { sx: '300px', md: '100%'}
          }}
        >
          <Canvas stageRefs={stageRefs}/>
        </Box>

        {/* PaintTools */}
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: { xs: 'flex-start', md: 'center' },
            flexGrow: 1,        // PaintTools solo ocupa su tamaño necesario
            padding: '10px 0',  // Añadimos un poco de espacio
          }}
        >
           <PaintTools stageRefs={stageRefs}/>
        </Box>
      </Box>
      <ColorTool />    
      <Box sx={{ display: 'flex', marginTop: '20px', justifyContent: 'flex-end', marginBottom: '20px', flexGrow: 1 }}>
        <Button onClick={()=> navigate('/')  }> {translate.backButton} </Button>
        <Button variant='outlined' sx={{ marginLeft: '20px' }} onClick={ ()=> {setOpenModal(true)} }> {translate.saveButton} </Button>
        <Button variant='contained' sx={{ marginLeft: '20px' }} onClick={() => handleDownload()}> {translate.exportButton} </Button>
      </Box>
      <SaveModal />
    </Box> 
  );
}

export default DrawBox;

