import React, { useEffect, useRef, useState } from 'react';
import { Stage, Layer, Line, Rect } from 'react-konva';
import { useSigilContext } from '../../context/sigilContext';
import { TOOLS } from '../../util/constants';

const Canvas = ({ stageRefs }) => {
    const {
        tool,
        color,
        shapes,
        setShapes,
        lineWidth
    } = useSigilContext()
    const [isDrawing, setIsDrawing] = useState(false);
    const [selectedShapeIndex, setSelectedShapeIndex] = useState(null);
    const [isMoving, setIsMoving] = useState(false);
    const [initialPos, setInitialPos] = useState(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const containerRef = useRef(null); 

    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                setDimensions({
                    width: containerRef.current.offsetWidth,
                    height: containerRef.current.offsetHeight,
                });
            }
        };

        updateDimensions(); 

        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    const handleMouseDown = (e) => {
        const pos = e.target.getStage().getPointerPosition();

        if (tool === TOOLS.SELECTOR) {
            handleSelectShape(pos);
            return;
        }
        if(tool === TOOLS.ERASE){
            handleDeleteShape(pos);
            return;
        }

        setIsDrawing(true);
        
        if (tool === TOOLS.PENCIL) {
            setShapes([...shapes, { tool, points: [pos.x, pos.y], color, lineWidth }]);
        }
    }

    const handleMouseMove = (e) => {
        if (!isDrawing && !isMoving) return;
        const stage = e.target.getStage();
        const pos = stage.getPointerPosition();

        if (isMoving && selectedShapeIndex !== null && initialPos) {
            const dx = pos.x - initialPos.x;
            const dy = pos.y - initialPos.y;

            const updatedShapes = shapes.map((shape, index) => {
                if (index !== selectedShapeIndex) return shape;

                if (shape.tool === 'line' || shape.tool === TOOLS.PENCIL) {
                    if (!shape.points) return shape; // Verificar si `points` existe

                    const updatedPoints = shape.points.map((point, i) =>
                        i % 2 === 0 ? point + dx : point + dy
                    );
                    return { ...shape, points: updatedPoints };
                } else {
                    const updatedShape = {
                        ...shape,
                        startX: shape.startX + dx,
                        startY: shape.startY + dy,
                    };
                    if (shape.endX !== undefined && shape.endY !== undefined) {
                        updatedShape.endX = shape.endX + dx;
                        updatedShape.endY = shape.endY + dy;
                    }
                    return updatedShape;
                }
            });

            setShapes(updatedShapes);
            setInitialPos(pos);

        }else if(isDrawing){
            if (tool === TOOLS.PENCIL) {
                const lastShape = shapes[shapes.length - 1];
                lastShape.points = lastShape.points.concat([pos.x, pos.y]);
                shapes.splice(shapes.length - 1, 1, lastShape);
                setShapes(shapes.concat());
            } 
        }


    }
    
    const handleMouseUp = () => {
        setIsDrawing(false);
        setIsMoving(false);
        setInitialPos(null);
    };

    const paintShape = ({index, shape, strokeColor}) =>  {
        const DRAWINGS = {
            PENCIL: (
                <Line
                    key={index}
                    points={shape.points}
                    stroke={strokeColor}
                    strokeWidth={shape.lineWidth}
                    tension={0.5}
                    lineCap="round"
                    lineJoin="round"
                />
            )
        }
        return DRAWINGS[shape.tool] || null;
    } 

    const renderShape = (shape, index) => {
        const isSelected = index === selectedShapeIndex;
        const strokeColor = isSelected && isMoving ? '#f3e5f5' : shape.color;
        return paintShape({tool, index, shape, strokeColor})
    }

    const getShapeIndexSelected = (pos) => {
        return shapes.findIndex((shape) => {
            if ( shape.tool === TOOLS.PENCIL) {
                // Verificamos si existen los puntos antes de intentar acceder a ellos
                if (!shape.points) return false;

                return shape.points.some((point, index) => {
                    if (index % 2 === 0) {
                        const x = shape.points[index];
                        const y = shape.points[index + 1];
                        const distance = Math.sqrt(Math.pow(pos.x - x, 2) + Math.pow(pos.y - y, 2));
                        return distance < 10;
                    }
                    return false;
                });
            }
        });
    }

    const handleSelectShape = (pos) => {
        const selectedShape = getShapeIndexSelected(pos);

        if(tool === TOOLS.ERASE){
            handleDeleteShape(selectedShape)
            return;
        }

        if (selectedShape !== -1) {
            setSelectedShapeIndex(selectedShape);
            setIsMoving(true);
            setInitialPos(pos);
        } else {
            setSelectedShapeIndex(null);
            setIsMoving(false);
        }
    };

    const handleDeleteShape = (pos) => {
        const shapeIndex = getShapeIndexSelected(pos);

        if (shapeIndex === null) return;
    
        // Crea un nuevo array sin la forma seleccionada
        const updatedShapes = shapes.filter((_, index) => index !== shapeIndex);
    
        // Actualiza el estado con las formas restantes
        setShapes(updatedShapes);
    
        // Resetea el índice de la forma seleccionada
        setSelectedShapeIndex(null);
    };

    const calculateHeight = (height) => {
       if(height <= 576){
        return 300;
       }
       if(height <= 768){
        return 500;
       }
       if(height <= 992){
        return 600;     
       }
       if(height > 992){
        return 800
       }
    }

    return (
        <div 
            ref={containerRef} // Asignamos la referencia al contenedor
            // style={{ 
            //     width: '100%', 
            //     height: { sx : '300px' , md: '300px'}, 
            //     flexGrow: 1 
            // }} 
        >
            <Stage
                width={window.innerWidth}
                height={calculateHeight(dimensions.width) }
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onTouchStart={handleMouseDown}
                onTouchMove={handleMouseMove}
                onTouchEnd={handleMouseUp}
                ref={stageRefs}
            >
                <Layer>
                    <Rect
                        x={20}
                        y={0}
                        width={window.innerWidth - 60}
                        height={calculateHeight(dimensions.width)}
                        fill="#2C2C2C" // Cambia esto por el color que quieras
                    />
                </Layer>
                <Layer>
                    {shapes.map((shape, i) => renderShape(shape, i))}
                </Layer>
            </Stage>
        </div>
    );
};

export default Canvas;
