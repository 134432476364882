import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import { useSigilContext } from '../../context/sigilContext';

const CustomSnackbar = () => {

  const { message, setMessage } = useSigilContext();

  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setMessage(null)
  };

  useEffect(() => {
    if(message){
      setOpen(true);
    }
  }, [message])

  return ( 
    <Snackbar 
      open={open} 
      autoHideDuration={6000} 
      onClose={handleClose}
    >
    <Alert
      onClose={handleClose}
      severity={message ? message.secerity : 'success'}
      variant="filled"
      sx={{ width: '100%' }}
    >
      {message ?  message.text : ''}
    </Alert>
  </Snackbar>

   );
}
 
export default CustomSnackbar;