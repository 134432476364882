import React, { useState } from 'react';
import FallingLettersTextBox from '../components/fallingLettersTextBox/FallingLettersTextBox';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useSigilContext } from '../context/sigilContext';
import { useNavigate } from 'react-router-dom';


const Home = () => {
    const navigate = useNavigate();
    const { 
        setSigilName,
        setShapes,
        setEditIndex,
        text,
        setText,
        setCriptoText,
        setFallingLetters,
        i18n
    } = useSigilContext()

    const cleanContext = () => {
        setShapes([]);
        setSigilName('');
        setEditIndex(undefined);
        processText()
        navigate('/drawing');
    }

    useState(()=> {
        setText('');
        setCriptoText('')
    }, []);

    const processText = () => {
        const uniqueLetters = [];
        const newFallingLetters = [];
    
        text.toLocaleUpperCase().split('').forEach((letter, index) => {
          if (!uniqueLetters.includes(letter) || letter === ' ') {
            uniqueLetters.push(letter);
          } else {
            newFallingLetters.push({ letter, index });
          }
        });
    
        // setFallingLetters(newFallingLetters);
        setText(uniqueLetters.join('')); // Actualizar el texto para eliminar repetidas
        // setCriptoText(uniqueLetters.join(''))
        // setTimeout(() => {
        //   setFallingLetters([]);
        setCriptoText(uniqueLetters.join(''))
        // }, 100);
    };

    const translate = i18n.creation;

    return ( 
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            marginX: '16px', 
            width: { sx: '100%', md: '50%'  },
            marginLeft: { md: '25%' }
        }}>
            <h1>{translate.title}</h1>
            <h3>{translate.description}</h3>
            <FallingLettersTextBox />
            <Box sx={{ display: 'flex' , width: '100%', justifyContent: 'flex-end' }}>
                <Button 
                    variant='contained' 
                    disabled={text === ''}
                    onClick={ () =>  {
                        cleanContext();
                    }}
                >
                    {translate.createButton}
                </Button>
            </Box>
        </Box>
    );
}
 
export default Home;

