import React from 'react';
import { useSigilContext } from '../../context/sigilContext';
import Drawer from '@mui/material/Drawer';
import { TOOLS } from '../../util/constants';
import { 
  SketchPicker
} from 'react-color';
import { IconButton } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import LineWidth from '../lineWidth/LineWidth';

const ColorTool = () => {
  const { 
    color, 
    setColor, 
    openColorTool,
    setOpenColorTool,
    setTool,
    setLineWidth,
    lineWidth
  } = useSigilContext();

  return ( 
    <Drawer 
      open={openColorTool} 
      onClose={ () => { setOpenColorTool(false); setTool(TOOLS.PENCIL) } }
      anchor='right'
      PaperProps={{
        sx: {
          backgroundColor: '#2C2C2C'
        }
      }}
    >
      <div style={{ 
        margin: 10, 
        display: 'flex',
        flexDirection: 'column', 
        justifyContent: 'space-between',
        flexGrow: 1,
        alignItems: 'center',
      }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <SketchPicker
            color={color}
            onChangeComplete={(color) => setColor(color.hex)}
            width='300px'
          />
          <LineWidth />
        </div>
        <IconButton 
          sx={{
            backgroundColor: 'white', 
            width: '54px',
            height: '54px', 
            marginBottom: '20px' 
          }}
          onClick={ () => { setOpenColorTool(false); setTool(TOOLS.PENCIL) }}
        > 
          <CloseOutlinedIcon />
        </IconButton>
      </div>
    </Drawer>
  );
}
 
export default ColorTool;