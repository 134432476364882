import React from "react";
import { Routes, Route } from 'react-router-dom'
import Home from "../pages/Home";
import DrawBox from "../pages/DrawBox";
import SigilToolbar from "../components/toolbar/SigilToolbar";
import InfoPage from "../pages/InfoPage";
import MySigils from "../pages/MySigils";
import "../App.css";
import CustomSnackbar from "../components/customSnackbar/CustomSnackbar";
import DrawerImage from "../components/drawerImage/DrawerImage";

function Main() {


return (
    <div className="App">
      < SigilToolbar/>
      <Routes>
        <Route path="/"  element={<Home />}/>
        <Route path="drawing"  element={<DrawBox />}/>
        <Route path="info"  element={<InfoPage />}/>
        <Route path="my-sigils"  element={<MySigils />}/>
      </Routes>
      <CustomSnackbar />
      <DrawerImage />
    </div>
  );
}

export default Main;
