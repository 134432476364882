import { createTheme } from '@mui/material/styles';


export const themeSigil = createTheme({
  typography: {
    fontFamily: 'Open Sans, sans-serif', // Configura Open Sans como fuente por defecto
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    primary: {
      main: '#FFFFFF', // Color blanco para el primary
    },
    secondary: {
      main: '#2C2C2C', // Color gris oscuro para el secondary
    },
    text: {
      primary: '#FFFFFF', // Color blanco por defecto para los textos
    },
    background: {
      default: '#2C2C2C'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:disabled': {
            color: '#5F6368', // Color del texto para todos los botones deshabilitados
          },
        },
        contained: {
          borderRadius: '18px', // Bordes redondeados solo en el botón 'contained'
          boxShadow: '0px 0px 14px 0px #FFFFFF', // Sombra blanca solo en el botón 'contained'
          '&:disabled': {
            boxShadow: 'none', // Sin sombra cuando está deshabilitado
            border: '1px solid #5F6368', // Borde para botón 'contained' deshabilitado
            backgroundColor: '#D9D9D9', // Fondo para botón 'contained' deshabilitado
          },
        },
        outlined: {
          borderRadius: '18px', // Bordes redondeados solo en el botón 'outlined'
          boxShadow: '0px 0px 14px 0px #FFFFFF', // Sombra blanca solo en el botón 'outlined'
          '&:disabled': {
            boxShadow: 'none', // Sin sombra cuando está deshabilitado
            border: '1px solid #5F6368', // Borde para botón 'outlined' deshabilitado
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: '#2C2C2C', // Fondo gris oscuro
          color: '#FFFFFF', // Texto blanco
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#2C2C2C', // Fondo gris oscuro
          color: '#FFFFFF', // Texto blanco
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filledSuccess: {
          backgroundColor: '#C1F1C0',
          color: '#0C470A',
        },
        filledInfo: {
          backgroundColor: '#C0DCF1',
          color: '#0D4B77', 
        },
        filledError: {
          backgroundColor: '#FFD179', 
          color: '#9B5600', 
        }
      },
    },
  },
});


