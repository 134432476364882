// import React from 'react';
// import { useSigilContext } from '../../context/sigilContext';

// const LineWidth = () => {

//   const { lineWidth, setLineWidth } = useSigilContext()

//   return (  
//       <div style={{ marginBottom: '10px', display: 'flex', flexGrow: 1 }}>
//         <label>Grosor de la línea: {lineWidth}px</label>
//         <input
//             type="range"
//             min="1"
//             max="20"
//             value={lineWidth}
//             onChange={(e) => setLineWidth(parseInt(e.target.value))}
//         />
//       </div>
//   );
// }
 
// export default LineWidth
 

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import BrushIcon from '@mui/icons-material/Brush';
import { useSigilContext } from '../../context/sigilContext';

const Input = styled(MuiInput)`
  width: 42px;
`;

export default function InputSlider() {
  // const [value, setValue] = React.useState(30);
  const { lineWidth, setLineWidth } = useSigilContext()

  const handleSliderChange = (event, newValue) => {
    setLineWidth(newValue);
  };

  const handleInputChange = (event) => {
    setLineWidth(event.target.value === '' ? 0 : Number(event.target.value));
  };

  const handleBlur = () => {
    if (lineWidth < 0) {
      setLineWidth(0);
    } else if (lineWidth > 100) {
      setLineWidth(100);
    }
  };

  return (
    <Box sx={{ width: 250, marginTop: '20px', display: 'flex', flexDirection: 'column',  width: '100%'  }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <BrushIcon />
        <Box  sx={{ paddingX: '10px', flexGrow: 1 }}>
          <Slider
            value={typeof lineWidth === 'number' ? lineWidth : 0}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
          />
        </Box>

        <Input
            value={lineWidth}
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 10,
              min: 0,
              max: 100,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
      </Box>
    </Box>
  );
}