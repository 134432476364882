export const en = {
  creation: {
    title: 'Sigils',
    description: 'Write your intention and with the remaining letters build your sigil.',
    placeholderBox: 'Write your intention here',
    createButton: 'Create sigil' 
  },
  drawing: {
    backButton: 'Back',
    saveButton: 'Save',
    exportButton: 'Export'
  },
  mySigils: {
    title: 'My Sigils',
    emptyList: `You don't have sigils yet.`
  },
  info: {
    title: 'Sigils',
    texts: [
      `"The magician may require something which he is unable to obtain through the normal channels. It is sometimes possible to bring about the required coincidence by the direct intervention of the will provided that this does not put too great a strain on the universe. The mere act of wanting is rarely effective, as the will becomes involved in a dialogue with the mind. This dilutes magical ability in many ways. The desire becomes part of the ego complex; the mind becomes anxious of failure. The will not to fulfill desire arises to reduce fear of failure. Soon the original desire is a mass of conflicting ideas. Often the wished for result arises only when it has been forgotten. This last fact is the key to sigils and most forms of magic spell. Sigils work because they stimulate the will to work subconsciously, bypassing the mind.`,
      `"There are three parts to the operation of a sigil. The sigil is constructed, the sigil is lost to the mind, the sigil is charged. In constructing a sigil, the aim is to produce a glyph of desire,  stylized so as not to immediately suggest the desire. It is not necessary to use complex symbol systems. Figure 2 shows how sigils may be constructed from words, from images, and from sounds. The subject matter of these spells is arbitrary and not recommended.`,
      `"To successfully lose the sigil, both the sigil form and the associated desire must be banished from normal waking consciousness. The magician strives against any manifestation of either by a forceful turning of his attention to other matters. Sometimes the sigil may be burnt, buried, or cast into an ocean. It is possible to lose a word spell by constant repetition as this eventually empties the mind of associated desire. The sigil is charged at moments when the mind has achieved quiescence through magical trance, or when high emotionality paralyzes its normal functioning. At these times the sigil is concentrated upon, either as a mental image, or mantra, or as a drawn form. Some of the times when sigils may be charged are as follows: during magical trance; at the moment of orgasm or great elation; at times of great fear, anger, or embarrassment; or at times when intense frustration or disappointment arises. Alternatively, when another strong desire arises, this desire is sacrificed (forgotten) and the sigil is concentrated on instead. After holding the sigil in the mind for as long as possible, it is wise to banish it by evoking laughter.`,
      `"A record should be kept of all work with sigils but not in such a way as to cause conscious deliberation over the sigilized desire."`
    ],
    imageName: 'Figure 2'
  },
  deleteModal: {
    title: 'Delete sigil',
    description: 'Are you sure about removing your sigil?',
    cancelButton: 'Cancel',
    deleteButton: 'Delete'
  },
  saveModal: {
    title: 'Save sigil',
    placeholder: 'Name',
    saveButton: 'Save'
  },
  menu: [
    'Create your sigil',
    'My sigils',
    'What is a sigil?'
  ],
  message: {
    save: {
      success: 'Your sigil has been saved',
      error: 'Error saving your sigil'
    },
    export: {
      success: 'Your sigil has been export',
      error: 'Could not save sigil'
    },
    delete:{
      success: 'You removed your sigil',
    }
  }

}