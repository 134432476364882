import React, {createContext, useContext, useState} from 'react';
import { TOOLS } from '../util/constants';
import { getLocate } from '../i18n';


const SigilContext = createContext(null);

export const SigilProvider = ({children}) => {
  const [tool, setTool] = useState(TOOLS.PENCIL)
  const [color, setColor] = useState('white');
  const [openColorTool, setOpenColorTool] = useState(false)
  const [lineWidth, setLineWidth] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [editIndex, setEditIndex] = useState(false);
  const [sigilName, setSigilName] = useState('');
  const [criptoText, setCriptoText] = useState('');
  const [originalText, setOriginalText] = useState('');
  const [shapes, setShapes] = useState([]);
  const [text, setText] = useState('');
  const [fallingLetters, setFallingLetters] = useState([]);
  const [savedName, setSavedName] = useState('');
  const [i18n, setI18n ] = useState();
  const [openImage, setOpenImage] = useState(false);

  const getUserLocate = () => {
    const language =  navigator.language || navigator.userLanguage;
    const locate= getLocate(language);
    setI18n(locate);
  }


  useState(()=> {
    getUserLocate();
  }, [])

  return (
    <SigilContext.Provider
      value={{
        tool,
        setTool,
        color,
        setColor,
        shapes,
        setShapes,
        openColorTool,
        setOpenColorTool,
        lineWidth,
        setLineWidth,
        criptoText,
        setCriptoText,
        setOpenModal,
        openModal,
        sigilName,
        setSigilName,
        message,
        setMessage,
        openDeleteModal,
        setOpenDeleteModal,
        editIndex,
        setEditIndex,
        originalText,
        setOriginalText,
        text,
        setText,
        fallingLetters,
        setFallingLetters,
        savedName,
        setSavedName,
        i18n,
        setI18n,
        openImage,
        setOpenImage
      }}
    >
      { children }
    </SigilContext.Provider>
  )
}


export const useSigilContext = () => useContext(SigilContext)


