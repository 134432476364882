import { Avatar, Box, List, ListItem, ListItemAvatar, Stack } from '@mui/material';
import React from 'react';
import BackHandIcon from '@mui/icons-material/BackHandOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import BrushIcon from '@mui/icons-material/BrushOutlined';
import PaletteIcon from '@mui/icons-material/PaletteOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { useSigilContext } from '../../context/sigilContext';
import { TOOLS } from '../../util/constants';
import { Eraser } from '../../assets/eraser';



const PaintTools = () => {

  const {
    tool,
    setTool,
    color,
    setColor,
    setShapes,
    setOpenColorTool
} = useSigilContext()


  const selectedToolColor = '#42a5f5';

  const oldVersion = () => (
    <Box>
      <List  component={Stack} direction='row' sx={{ width: '55px', justifyContent: 'flex-start', marginRight: '15px' }}>
        <ListItem>
            <ListItemAvatar
              sx={{ cursor: 'pointer' }}
              onClick={ () => { setTool(TOOLS.PENCIL) } }
            >
              <Avatar sx={{  backgroundColor: tool === TOOLS.PENCIL ? selectedToolColor : 'white'}}>
                <BrushIcon />
              </Avatar>
            </ListItemAvatar>
        </ListItem>
        <ListItem>
            <ListItemAvatar
              sx={{ cursor: 'pointer' }}
              onClick={ () => { setTool(TOOLS.PALETTE); setOpenColorTool(true) } } 
            >
              <Avatar sx={{  backgroundColor: tool === TOOLS.PALETTE ? selectedToolColor : 'white'}}>
                <PaletteIcon />
              </Avatar>
            </ListItemAvatar>
        </ListItem>   
        <ListItem>
            <ListItemAvatar
              sx={{ cursor: 'pointer' }}
              onClick={ () => { setTool(TOOLS.SELECTOR) } }
            >
              <Avatar sx={{  backgroundColor: tool === TOOLS.SELECTOR ? selectedToolColor : 'white'}}>
                <BackHandIcon />
              </Avatar>
            </ListItemAvatar>
        </ListItem>
        <ListItem>
            <ListItemAvatar 
              sx={{ cursor: 'pointer' }}
              onClick={ () => { setTool(TOOLS.ERASE) } }
            >
              <Avatar sx={{  backgroundColor: tool === TOOLS.ERASE ? selectedToolColor : 'white'}}>
                <Eraser />
              </Avatar>
            </ListItemAvatar>
        </ListItem>
        <ListItem>
            <ListItemAvatar
              sx={{ cursor: 'pointer' }}
              onClick={ () => { setShapes([]); setTool(TOOLS.CLEAR) } }
            >
              <Avatar sx={{  backgroundColor: tool === TOOLS.CLEAR ? selectedToolColor : 'white'}}>
                <DeleteIcon />
              </Avatar>
            </ListItemAvatar>
        </ListItem>
      </List>
    </Box>

  )

  return  oldVersion()
    

   ;
}
 
export default PaintTools;
