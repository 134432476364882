import React, { useEffect, useState } from 'react';
import './FallingLetters.css';
import { useSigilContext } from '../../context/sigilContext';

const FallingLettersTextBox = ({}) => {
  // const [fallingLetters, setFallingLetters] = useState([]);
  const { 
    setCriptoText, 
    setOriginalText, 
    text, 
    setText,
    fallingLetters,
    setFallingLetters,
    i18n
  } = useSigilContext() 

  const handleChange = (e) => {
    setOriginalText(e.target.value)
    setText(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      processText();
    }
  };

  const processText = () => {
    const uniqueLetters = [];
    const newFallingLetters = [];

    text.toLocaleUpperCase().split('').forEach((letter, index) => {
      if (!uniqueLetters.includes(letter) || letter === ' ') {
        uniqueLetters.push(letter);
      } else {
        newFallingLetters.push({ letter, index });
      }
    });

    setFallingLetters(newFallingLetters);
    setText(uniqueLetters.join('')); // Actualizar el texto para eliminar repetidas
    // setCriptoText(uniqueLetters.join(''))
    setTimeout(() => {
      setFallingLetters([]);
      setCriptoText(uniqueLetters.join(''))
    }, 100);
  };

  const translate = i18n.creation;

  return (
    <div className="text-box-container">

      <div className="text-box">
        {text.length === 0 && (
          <span className="text-letter falling-initial" style={{ color: '#D9D9D980', overflow: 'auto' }}>
            {translate.placeholderBox}
          </span>
        )}
        {text.split('').map((letter, index) => (
          <span key={index} className="text-letter falling-initial">
            {letter}
          </span>
        ))}
        {fallingLetters.map(({ letter, index }) => (
          <span
            key={index}
            className="falling-letter"
            style={{ left: `${index * 16}px` }}
          >
            {letter}
          </span>
        ))}
      </div>
      <input
        type="text"
        value={text}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        placeholder={translate.placeholderBox}
        className="hidden-input"
      />
    </div>
  );
};

export default FallingLettersTextBox;

